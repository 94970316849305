import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/retouch/retouchiingbefore.jpg';
import after from '../../../../assets/images/beforeafter/retouch/retouchiingafter.jpg';
const PhotoRetouchingba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default PhotoRetouchingba;