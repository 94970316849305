import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/CPH.png';
import Scroll from '../Scroll/Scroll';
import './Header.css';
import { AuthContext } from '../../component/providers/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';



const Header = () => {

   // button
   const {user,logOut } = useContext(AuthContext);

 const handleLogout = () => {
       logOut()
       .then(result => {})
       .catch(error => console.error(error));
 }
   const menuItems = <React.Fragment>
         
         <li ><Link to='/'>Home</Link></li>
            <li tabIndex={0}>
              <Link >
                Services
                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
              </Link>
              <ul className="p-2 bg-blue-600 text-white">
                <li><Link to='/clippingpath'>Clipping Path</Link></li>
                <li><Link to='/backgroundremoval'>Background Removal</Link></li>
                <li><Link to='/imagemasking'>Image Masking</Link></li>
                <li><Link to='/neckjoint'>Neck Joint</Link></li>
                <li><Link to='/shadow'>Shadow</Link></li>
                <li><Link to='/ghostmannequin'>Ghost Mannequin</Link></li>
                <li><Link to='/photoretouching'>Photo Retouching</Link></li>
                <li><Link to='/colorchanges'>Color Changes</Link></li>
                <li><Link to='/vectorservices'>Vector Services</Link></li>
                <li><Link to='/multiclippingpath'>Multi Clipping Path </Link></li>
                

              </ul>
            </li>
            <li><Link to='/contactus'>Contact</Link></li>
            <li><Link to='/pricing'>Pricing</Link></li>
            <li><Link to='/aboutus'>About Us</Link></li>
            <li><Link to='/blog'>Blog</Link></li>
            <li><Link to='/howtoitswork'>How its Work</Link></li>
            <li><Link >{user &&  <p>Welcome  <span className='text-light lg:text-blue-600'> {user?.displayName}</span></p>}    </Link></li>
             
   </React.Fragment>
    return (
        <div className="navbar fixed shadow-md bg-light text-black bg-white  z-50 w-full px-5 py-2 flex justify-between items-center ">
        <div className="navbar-start">
          <div className="dropdown ">
            <label tabIndex={0} className="btn btn-ghost lg:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-blue-600 lg:text-dark text-white rounded-box w-52">
            {menuItems}
            </ul>
          </div>
          <Link to='/'> <img className='h-15' src={logo} alt='Clipping Path India' /></Link>
       
        </div>
        <div className="navbar-center hidden lg:flex ">
          <ul className="menu menu-horizontal px-1 ">
              {menuItems}
          </ul>
        </div>
        <div className="navbar-end">
           
          
          {!user && <Link className="btn bg-blue-600 mx-5 rounded-none text-white border-blue-600  hover:border-2 hover:border-blue-600 hover:bg-white hover:text-blue-600" to='/login'>Login</Link>}
        
          {user &&
            <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
        <div className="w-10 rounded-full">
        
       

          {             
              user?.photoURL ?
              <img alt="Tailwind CSS Navbar component" src={user.photoURL} />
               :
               <span className='text-4xl  text-blue-600'><FontAwesomeIcon  icon={faUser}></FontAwesomeIcon></span>
             } 

        </div>
      </div>
      <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52 bg-gradient-to-r from-blue-600 to-blue-400 text-white">
        <li>
          <Link to='/profile' className="justify-between">
            Profile
            <span className="badge">New</span>
          </Link>
        </li>
        <li><button>Settings</button></li>
        <li><button onClick={handleLogout}>Logout</button></li>
      </ul>
      </div>
      }

        </div>
        <Scroll></Scroll>
        {/* <Whatsapp></Whatsapp> */}
      </div>
    );
};

export default Header;