import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/mannequeen/mannequeenbefore.jpg';
import after from '../../../../assets/images/beforeafter/mannequeen/mannequeenafter.jpg';
const GhostMannequinba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default GhostMannequinba;