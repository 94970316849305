import React, { useRef } from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import './Slider.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import retouch from '../../../../assets/images/retouch.jpg';
import masking from '../../../../assets/images/masking.JPG';
import shadow from '../../../../assets/images/shadow.jpg';
import neckjoint from '../../../../assets/images/neck.jpg';
import colorcorrection from '../../../../assets/images/clorcuration.jpg';
import backgroundremove from '../../../../assets/images/background remove.jpg';
import clippingpath from '../../../../assets/images/clipping path.jpg';
import jewlary from '../../../../assets/images/jewlary.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';

const Slider = () => {
    const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 2000)}s`;
  };
    return (
       
     <Swiper 
 
     spaceBetween={30}
     centeredSlides={true}
     autoplay={{
       delay: 2500,
       disableOnInteraction: false,
     }}
     pagination={{
       clickable: true,
     }}
     navigation={true}
     modules={[Autoplay, Pagination, Navigation]}
     onAutoplayTimeLeft={onAutoplayTimeLeft}
     className="mySwiper"
     >
        
     
      <SwiperSlide><img src={retouch} alt='Retouching'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={masking} alt='Masking'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={shadow} alt='Shadow'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={neckjoint} alt='Neckjoint'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={colorcorrection} alt='Color Correction'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={clippingpath} alt='Clipping Parh'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={backgroundremove} alt='Background Remove'  className='rounded-lg '/></SwiperSlide>
      <SwiperSlide><img src={jewlary} alt='Jewlary'  className='rounded-lg '/></SwiperSlide>
     
   
        
      
      
        <div className="autoplay-progress " slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
     </Swiper>
    );
};

export default Slider;



