import React, { useRef } from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import './HomeCat.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Swiper, SwiperSlide } from 'swiper/react';
// import ban01 from '../../../assets/images/ban01 (1).png';
import ban02 from '../../../assets/images/ban01 (2).png';
// import ban03 from '../../../assets/images/ban01 (3).png';
import ban04 from '../../../assets/images/ban01 (4).png';

const HomeCat = () => {
    const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
    return (
       
     <Swiper 
 
     spaceBetween={30}
     centeredSlides={true}
     autoplay={{
       delay: 2500,
       disableOnInteraction: false,
     }}
     pagination={{
       clickable: true,
     }}
     navigation={true}
     modules={[Autoplay, Pagination, Navigation]}
     onAutoplayTimeLeft={onAutoplayTimeLeft}
     className="mySwiper"
     >
        
     
      {/* <SwiperSlide><img src={ban01} alt='clipping Path'  className='rounded-lg '/></SwiperSlide> */}
        <SwiperSlide><img src={ban02} alt='clipping Path' className='rounded-lg ' /></SwiperSlide>
        {/* <SwiperSlide><img src={ban03} alt='clipping Path' className='rounded-lg ' /></SwiperSlide> */}
        <SwiperSlide><img src={ban04} alt='clipping Path' className='rounded-lg ' /></SwiperSlide>
     
   
        
      
      
        <div className="autoplay-progress container mx-auto" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
     </Swiper>
    );
};

export default HomeCat;



