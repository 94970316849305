import React from 'react';
import ColorChangesba from './ColorChangesba/ColorChangesba';
import ClippingPathba from './ClippingPathba/ClippingPathba';
import BackgroundRemovalba from './BackgroundRemovalba/BackgroundRemovalba';
import ImageMaskingba from './ImageMaskingba/ImageMaskingba';
import Shadowba from './Shadowba/Shadowba';
import MultiClippingPathba from './MultiClippingPathba/MultiClippingPathba';
import PhotoRetouchingba from './PhotoRetouchingba/PhotoRetouchingba';
import { Link } from 'react-router-dom';
import NeckJoint from './NeckJointba/NeckJointba';
import GhostMannequinba from './GhostMannequinba/GhostMannequinba';
import vector from '../../../assets/images/service/vectorservice.jpg';

const ServiceCard = () => {
    return (
        <div className='container mx-auto '>
          <h3 className='text-4xl font-bold text-blue-600 pt-10'>Our Services</h3>
     <div className='grid lg:grid-cols-2 grid-cols-1'>
     <div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <ClippingPathba></ClippingPathba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Clipping Path</h2>
    <p>A clipping path is a technique used in image editing to create a vector-based outline or path around a specific object or subject within an image. This path can then be used to separate the object from its background or to apply various image editing effects to the object while keeping it isolated.</p>
    <div className="card-actions">
      <Link   to='/clippingpath' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <BackgroundRemovalba></BackgroundRemovalba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Background Removal</h2>
    <p>Background removal is the process of isolating the main subject of an image or photograph from its background. It allows you to separate the foreground object or person from the rest of the image, making it easier to manipulate, enhance, or place the subject onto a different background.</p>
    <div className="card-actions">
      <Link  to='/backgroundremoval' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <ImageMaskingba></ImageMaskingba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Image Masking</h2>
    <p>Image masking is a technique used in image editing to selectively hide or reveal certain portions of an image. It is particularly useful when dealing with complex or fine details like hair, fur, or transparent objects, where traditional selection methods like clipping paths or basic selections may not provide satisfactory results.</p>
    <div className="card-actions">
      <Link  to='/imagemasking' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <Shadowba></Shadowba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Shadow</h2>
    <p>In the context of image editing and graphic design, a shadow refers to a visual effect that imitates the appearance of a shadow cast by an object in a scene. Adding shadows to objects can enhance their realism and create a sense of depth and dimension within an image.</p>
    <div className="card-actions">
      <Link  to='/shadow' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
   <GhostMannequinba></GhostMannequinba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Ghost Mannequin</h2>
    <p>Ghost mannequin, also known as invisible mannequin or hollow man photography, is a technique commonly used in the fashion industry to create a seamless and professional look for clothing product images. It involves photographing garments on a mannequin and then digitally removing the mannequin from the image, leaving behind a "ghost-like" appearance that showcases the clothing's shape and fit.</p>
    <div className="card-actions">
      <Link  to='/ghostmannequin' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <PhotoRetouchingba></PhotoRetouchingba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Photo Retouching</h2>
    <p>
Photo retouching is the process of making adjustments and enhancements to an image to improve its overall appearance, correct imperfections, and create the desired visual effect. It involves using various techniques and tools in image editing software to enhance colors, tones, textures, and other aspects of the image</p>
    <div className="card-actions">
      <Link  to='/photoretouching' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
   <ColorChangesba></ColorChangesba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Color Changes</h2>
    <p>Color changes in images refer to the process of altering the colors of an image to achieve a different look, mood, or aesthetic. It involves modifying the hue, saturation, and brightness levels of specific colors or the entire image to create a desired color scheme or to match a specific style or branding.</p>
    <div className="card-actions">
      <Link  to='/colorchanges' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
  <NeckJoint></NeckJoint>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Neck Joint</h2>
    <p>Neck joint, also known as ghost mannequin or invisible mannequin effect, is a technique commonly used in apparel product photography to showcase clothing items while maintaining their proper shape and fit. It involves combining multiple images of the front and back of a garment, along with shots of the inner neck area, to create a seamless and complete view of the clothing item without the mannequin or model</p>
    <div className="card-actions">
      <Link  to='/neckjoint' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <img src={vector} alt='Vector Service' />
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Vector Services</h2>
    <p>Vector services refer to the process of converting raster-based images or graphics into vector format. Raster images are made up of pixels, while vector graphics are based on mathematical equations and can be scaled to any size without losing quality. Vector services are commonly used in various industries, including graphic design, printing, signage, embroidery, and more. Here are some common vector services:</p>
    <div className="card-actions">
      <Link  to='/vectorservices' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    </div>
  </div>
</div>

<div className="card ml-5 mt-10 bg-base-100 shadow-xl">
  <figure className=" ">
    <MultiClippingPathba></MultiClippingPathba>
  </figure>
  <div className="card-body items-center text-center">
    <h2 className="card-title text-3xl font-bold text-blue-600">Multi Clipping Path </h2>
    <p>Multi-clipping path, also known as complex or advanced clipping path, is an advanced image editing technique used to isolate and manipulate multiple objects or components within an image. It involves creating separate paths or shapes around each individual element, allowing for independent editing, adjustment, or removal of specific parts.</p>
    <div className="card-actions">
      <Link  to='/multiclippingpath' className="btn border-blue-600 hover:border-blue-600 hover:bg-white border-2 hover:text-blue-600 bg-blue-600">Read More</Link>
    
    </div>
  </div>
</div>


     </div>

            
        </div>
    );
};

export default ServiceCard;