import React from 'react';

import { Link } from 'react-router-dom';


const WatchNow = () => {
    return (
        <div className='container mx-auto'>
            <div className="grid grid-cols-2 mt-20 " >
            <div className="card lg:card-side  shadow-xl  ">
              
       
        <div className="card-body">
 
    <h2 className="card-title text-4xl font-bold text-blue-600">Know Clipping Path India</h2>
    <p>With their comprehensive range of services, <br></br>Clipping Path Hero is committed to delivering exceptional image editing solutions,<br></br> meeting the diverse needs of clients from various industries <br></br> such as e-commerce, advertising, photography, and more.</p>
    <div className="card-actions justify-end ">
   
      <Link to='/aboutus' className="btn border-2 border-blue-600 hover:border-blue-600 bg-blue-600 hover:bg-white text-white hover:text-blue-600">Read More</Link>
    </div>
  </div>


 
              
           
</div> 


<div>

</div>
           </div>
        </div>
    );
};

export default WatchNow;