import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/masking/before/maskbefore1.jpg';
import after from '../../../../assets/images/beforeafter/masking/before/maskafter.jpg';

const ImageMaskingba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default ImageMaskingba;