import React from 'react';
import TestServe from '../TestServe/TestServe';
import useTitle from '../../../hooks/useTitle';

const NeckJoint = () => {
    useTitle("Neck Joint");
    return (
        <div>
            <TestServe></TestServe>
        </div>
    );
};

export default NeckJoint;