import { createBrowserRouter } from "react-router-dom";
import Main from "../../layout/Main";
import Home from  '../../component/Home/Home';
import Error404 from "../../Pages/Error404/Error404";
import Login from "../../Pages/Login/Login";
import Register from "../../Pages/Register/Register";
import AboutUs from "../../Pages/AboutUs/AboutUs";
import ContactUs from "../../Pages/ContactUs/ContactUs";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfCondition from "../../Pages/TermsOfCondition/TermsOfCondition";
import CookiePolicy from "../../Pages/CookiePolicy/CookiePolicy";
import ClippingPath from "../../Pages/Services/ClippingPath/ClippingPath";
import BackgroundRemoval from "../../Pages/Services/BackgroundRemoval/BackgroundRemoval";
import ImageMasking from "../../Pages/Services/ImageMasking/ImageMasking";
import Shadow from "../../Pages/Services/Shadow/Shadow";
import GhostMannequin from "../../Pages/Services/GhostMannequin/GhostMannequin";
import PhotoRetouching from "../../Pages/Services/PhotoRetouching/PhotoRetouching";
import ColorChanges from "../../Pages/Services/ColorChanges/ColorChanges";
import VectorServices from "../../Pages/Services/VectorServices/VectorServices";
import MultiClippingPath from "../../Pages/Services/MultiClippingPath/MultiClippingPath";
import Blog from "../../Pages/Blog/Blog";
import Services from "../../Pages/Services/Services";
import HowToWotk from "../../Pages/HowToWotk/HowToWotk";
import Pricing from "../../Pages/Pricing/Pricing";
import NeckJoint from "../../Pages/Services/NeckJoint/NeckJoint";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Profile from "../../Pages/Profile/profile";
export const Routes = createBrowserRouter([
     {
         path:'/',
         element: <Main></Main>,
         children: [
             {
                path: '/',
                element: <Home></Home>

             },
             {
                path: '/login',
                element : <Login></Login>
             },
             {
                path: '/register',
                element: <Register></Register>
             },
             {
                path: '/aboutus',
                element: <AboutUs></AboutUs>
             },
             {
                path: '/contactus',
                element: <ContactUs></ContactUs>
             },
             {
                path: '/privacypolicy',
                element: <PrivacyPolicy></PrivacyPolicy>
             },
             {
                path: '/termsofcondition',
                element: <TermsOfCondition></TermsOfCondition>
             },
             {
                path: '/cookiepolicy',
                element: <CookiePolicy></CookiePolicy>
             },
             {
               path : '/blog',
               element: <Blog></Blog>
             },
             {
                path: '/clippingpath',
                element: <ClippingPath></ClippingPath>
             },
             {
                path: '/backgroundremoval',
                element: <BackgroundRemoval></BackgroundRemoval>
             },
             {
                path: '/imagemasking',
                element: <ImageMasking></ImageMasking>
             },
             {
                path: '/shadow',
                element: <Shadow></Shadow>
             },
             {
                path: '/ghostmannequin',
                element: <GhostMannequin></GhostMannequin>
             },
             {
                path: '/photoretouching',
                element: <PhotoRetouching></PhotoRetouching>
             },
             {
                path: '/colorchanges',
                element: <ColorChanges></ColorChanges>
             },
             {
                path: '/vectorservices',
                element: <VectorServices></VectorServices>
             },
             {
                path: '/multiclippingpath',
                element: <MultiClippingPath></MultiClippingPath>
             },
             {
                path: '/neckjoint',
                element: <NeckJoint></NeckJoint>
             },
             {
               path : '/services',
               element: <Services></Services>
             },
             {
               path: '/howtoitswork',
               element: <HowToWotk></HowToWotk>
             },
             {
               path: '/pricing',
               element: <PrivateRoute> <Pricing></Pricing> </PrivateRoute>
             }
             ,

             {
               path: '/profile',
               element: <PrivateRoute> <Profile></Profile> </PrivateRoute>
             }
         ]
     },
      
     {
        path:'*',
        element: <Error404></Error404>
     }
]);