import { faBarChart, faChartBar, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import useTitle from '../../hooks/useTitle';
import axios from 'axios';
import { toast } from 'react-toastify';


const ContactUs = () => {


     useTitle("Contact Us");

     const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      service: '',
      message: '',
    });
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        await axios.post('https://api.clippingpaths.in/submitForm', formData);
        toast.success('Form submitted successfully!', {
          position: toast.POSITION.TOP_CENTER ,
        });
        // You can redirect or perform other actions after successful form submission
      } catch (error) {
        console.error('Error submitting form:', error);
 
        toast.warning('An error occurred while submitting the form. Please try again.', {
          position: toast.POSITION.TOP_CENTER ,
        });
      }
    };


    return (
        <div className='mt-20 container mx-auto ' >
          
          {/* Use Google Font */}
        

              <div className='lg:pt-20 pt-10 pb-20 grid grid-cols-1'>
              <h2 className='text-4xl lg:pb-20 pb-10 font-bold text-blue-600 text-center'>Keep in Touch</h2>
              <div className="card lg:card-side grid lg:grid-cols-2 grid-cols-1 gap-8 " data-aos="flip-right">
      <div className='lg:ml-40 px-10 col-span-1'>
          
           <p className='text-1xl text-slate-400 pt-3'>Keeping in touch with people is an important part of maintaining relationships, both personally and professionally. In today's fast-paced world, it can be easy to lose touch with those we care about or work with, but there are many ways to stay connected.</p>
           
              <h5 className='pt-5 text-2xl font-bold text-blue-600'><span></span> Visit Us :</h5>
              <p className='pl-5 text-1xl'>Neuburger Str. 5, 85057 Ingolstadt, Germany</p>

              <h5 className='text-2xl font-bold text-blue-600'><span> </span> Drop Us :</h5>
              <p className='pl-5 text-1xl'>info@clippingpaths.in</p>

              <h5 className='text-2xl font-bold text-blue-600'><span> </span> Call Us :</h5>
              <p className='pl-5 text-1xl'>Call:  +88 01971-632571</p>



              
      </div>

  <div className="card-body lg:ml-20 col-span-1 " data-aos="flip-left">
        <div >
        <div className="form-control ">
          <form onSubmit={handleSubmit}>

          

  <label className="input-group ">
    <span className='text-blue-600'><FontAwesomeIcon icon={faUser} /></span>
    <input type="text" name='name' placeholder="Type Your Name" className="input input-bordered w-full" />
  </label>

  <label className="input-group pt-3 ">
    <span className='text-blue-600'> <FontAwesomeIcon icon={faEnvelope}/></span>
    <input type="email" name='email' placeholder="Type Your Email" className="input input-bordered w-full" />
  </label>
  <label className="input-group pt-3">
    <span className='text-blue-600'><FontAwesomeIcon icon={faPhone} /></span>
    <input type="phone" name='phone' placeholder="Type Your Phone Number" className="input input-bordered w-full" />
  </label>
   
  <label className="input-group pt-3 ">
  <span className='text-blue-600'><FontAwesomeIcon icon={faBarChart}/></span>
  <select
                name="service"
                id="pet-select"
                className='w-full p-5'
                value={formData.service}
                onChange={handleChange}
              >
                <option value="" className='text-1xl font-bold text-blue-600'>Please Choose a Service</option>
                <option value="Clipping Path" className='text-1xl font-bold text-blue-600'>Clipping Path</option>
                <option value="Background Removal" className='text-1xl font-bold text-blue-600'>Background Removal</option>
                <option value="Image Masking" className='text-1xl font-bold text-blue-600'>Image Masking</option>
                <option value="Shadow" className='text-1xl font-bold text-blue-600'>Shadow</option>
                <option value="Ghost Mannequin" className='text-1xl font-bold text-blue-600'>Ghost Mannequin</option>
                <option value="Photo Retouching" className='text-1xl font-bold text-blue-600'>Photo Retouching</option>
                <option value="Color Changes" className='text-1xl font-bold text-blue-600'>Color Changes</option>
                <option value="Vector Services" className='text-1xl font-bold text-blue-600'>Vector Services</option>
                <option value="Multi Clipping Path" className='text-1xl font-bold text-blue-600'>Multi Clipping Path</option>
              </select>
            </label>


  <label className="input-group pt-3 ">
    <span className='text-blue-600'><FontAwesomeIcon icon={faChartBar}/></span>
  
   <textarea className='w-full ' placeholder='Type Your Message'     name="message"
    onChange={handleChange}></textarea>
  </label>


  
  <button className="btn rounded mt-5 w-40 bg-blue-600  border-blue-600 hover:border-2 hover:bg-white  hover:text-blue-600  hover:border-blue-600 shadow-lg" type='submit'>Submit</button>
  </form>
</div>
        </div>
  </div>
</div>
              </div>
        </div>
    );
};

export default ContactUs;