import React from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import Slider from './Slider/Slider';


const Banner = () => {
    return (
        <div className='mx-auto container grid lg:grid-cols-2 grid-cols-1  lg:pt-0 pt-5 ' >
               <div>
               <div className="card  text-left  " >
  <div className="card-body">
    <h2 className="card-title lg:text-5xl text-4xl font-bold text-black"> Ultimate solution for your product photo   </h2>
     <h3 className=' lg:text-5xl text-3xl font-extrabold pt-2  text-transparent  bg-clip-text bg-gradient-to-r from-blue-400 to-blue-800'>
     <TypeAnimation sequence={[
         'Photo Retouching ',
              1000, 
        'Image Masking ', 
              1000, 
        // 'Clipping Path', 
        // 1000, 
        'Shadow', 
         1000, 
         'Neck Joint ', 
         1000, 
        // 'Ghost Mannequin ', 
        // 1000, 
        // 'Background Removal ', 
        // 1000, 
        // 'Color Changes ', 
        // 1000, 
        // 'Vector Services ', 
        // 1000, 
        // 'Multi Clipping Path ', 
        // 2000, 
        () => {
         // console.log('Done typing!'); // Place optional callbacks anywhere in the array
        }
      ]}
      wrapper="h2"
      cursor={true}
      repeat={Infinity}
      style={{ fontSize: '5xl'   }}>

   </TypeAnimation>
     </h3>
    <p className='pt-5 pb-5'>Whether you require a clean and simple white background or a more intricate clipping path, our team of experts will provide you with pixel-perfect image editing services whenever you need them. Trust us to deliver exceptional results that will elevate your product photography and take your business to the next level.</p>
    <div className="card-actions ">
     

      <Link to='/pricing'  className="btn rounded-none  bg-blue-600  border-blue-600 hover:border-2 hover:bg-white  hover:text-blue-600  hover:border-blue-600 shadow-lg">TRY NOW</Link>
     
      <Link to='/contactus' className="btn rounded-none  bg-white text-blue-600 border-blue-600 border-2 hover:bg-blue-600  hover:text-white hover:border-none">Contact Us</Link>
    </div>
  </div>
</div>
               </div>
      {/* slider */}
   
               <div className='px-5 lg:px-none h-96'>
                
                <Slider></Slider>
           
              
               </div>
            
        </div>
    );
};

export default Banner;