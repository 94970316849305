
import './TestServe.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';
import neck  from '../../../assets/images/service/Neck Joint.jpg';

const TestServe = () => {
    return (
        <div className='lg:mt-2 mt-1 mb-10 '>
           

            {/* post */}

            <div className='grid grid-cols-1 container mx-auto px-5'>
                  <h1 className='text-4xl pt-10 text-blue-600'>Neck Joint</h1>
                  <p className='pt-5'>What is a Neck Joint service? What are the different types of Neck Joints, and what are they used for? What is the three-stage quality control system that Creative Neck Joint Limited uses? How are Neck Joints drawn, and what are the advantages of using Neck Joint services? When is it appropriate to use a Neck Joint service?</p>
                  <h1 className='text-3xl pt-10 text-blue-600'>Basic Neck Joint </h1>
                  <p className='pt-5'>What is a Neck Joint service? What are the different types of Neck Joints, and what are they used for? What is the three-stage quality control system that Creative Neck Joint Limited uses? How are Neck Joints drawn, and what are the advantages of using Neck Joint services? When is it appropriate to use a Neck Joint service?</p>
                  <p className='pt-5'>What is a Neck Joint service? What are the different types of Neck Joints, and what are they used for? What is the three-stage quality control system that Creative Neck Joint Limited uses? How are Neck Joints drawn, and what are the advantages of using Neck Joint services? When is it appropriate to use a Neck Joint service?</p>

                  {/* card */}
                 <img src={neck}  className='rounded-xl' alt='Neck  Joint' />

                 <p>
                 In today's fast-paced digital world, images play a vital role in catching the eye of potential customers. A clean, polished, and visually appealing image can make all the difference in a customer's purchasing decision. That's where the basic Neck Joint service comes in.

What is a basic Neck Joint service?
A basic Neck Joint service is a type of image editing service that is used to extract an object or subject from its background. It involves creating a vector path around the object, which can then be used to remove the background, change the color, or apply any other editing effects to the image.

When is a basic Neck Joint service required?
A basic Neck Joint service is required when the object or subject in the image has a simple and straightforward shape, with no intricate details or curves. It is typically used for products such as ball, spoon, mobile phone, framed art, plate, ring, egg, book, and similar items.

How is a basic Neck Joint service performed?
A basic Neck Joint service is typically performed using Adobe Photoshop or other similar image editing software. The process involves creating a vector path around the object using the pen tool. The path is then refined and smoothed out, ensuring that there are no rough or jagged edges. The background is then removed using the path, and any other editing effects are applied as required.


                 </p>
                {/* faq */}

                <h3 className='text-3xl mt-5 text-blue-600 font-bold'>Frequently Asked Questions</h3>
  <div className='grid grid-cols-2'>
  <div>
       <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-200  shadow-lg rounded-box mt-5">
  <div className="collapse-title text-xl font-medium">
  Improved image quality:
  </div>
  <div className="collapse-content">
    <p>tabIndex={0} By removing the background and isolating the object, a basic Neck Joint service can significantly improve the image quality, making it more visually appealing and professional-looking.</p>
  </div>
</div>

    
     
   
        <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-200  shadow-lg rounded-box mt-5">
  <div className="collapse-title text-xl font-medium">
  Cost-effective :
  </div>
  <div className="collapse-content">
    <p>tabIndex={0}  A basic Neck Joint service is a cost-effective solution for businesses looking to improve their product images without breaking the bank.</p>
  </div>



        </div>

        <div tabIndex={0} className="collapse collapse-plus border border-base-300 bg-base-200  shadow-lg rounded-box mt-5">
  <div className="collapse-title text-xl font-medium">
 Increased sales:
  </div>
  <div className="collapse-content">
    <p>tabIndex={0} High-quality product images can have a significant impact on sales. By using a basic Neck Joint service to enhance product images, businesses can increase sales and drive revenue.</p>
  </div>
 
       </div>

           {/* div two  */}
           <div className='pt-10'>
           <Link to='/pricing' className='btn bg-white border-blue-600 border-2 text-blue-600 hover:bg-blue-600 hover:border-blue-600 hover:text-white'> <FontAwesomeIcon icon={faArrowPointer} className='pr-2' fa-beat/> Get a Quote</Link>
       </div>

    </div>
     {/* div one */}
      
    


        </div>

       
        {/* faq end */}
        <p className='pt-5'>Benefits of a basic Neck Joint service
A basic Neck Joint service offers several benefits, including:

Improved image quality: By removing the background and isolating the object, a basic Neck Joint service can significantly improve the image quality, making it more visually appealing and professional-looking.

Cost-effective: A basic Neck Joint service is a cost-effective solution for businesses looking to improve their product images without breaking the bank.

Increased sales: High-quality product images can have a significant impact on sales. By using a basic Neck Joint service to enhance product images, businesses can increase sales and drive revenue.

In conclusion, a basic Neck Joint service is a must-have for businesses looking to improve their product images and drive sales. It is a cost-effective and efficient solution that can significantly enhance the quality of images, making them more visually appealing and professional-looking.</p>
            </div>

            </div>
        
       
    );
};

export default TestServe;