import React from "react";
import Background from "../../assets/images/floating_elements.png";
import Banner from "./Banner/Banner";
import Category from "./Category/Category";
import useTitle from "../../hooks/useTitle";
 import WatchNow from "./WatchNow/WatchNow";
import LatestBlog from "./LatestBlog/LatestBlog";
import Subscribe from "./Subscribe/Subscribe";
import ServiceCard from "./ServiceCard/ServiceCard";
const Home = () => {
  useTitle("Home");
  return (
    <div
      className="lg:pt-40 pt-10 text-center  "
      style={{ background: `url(${Background})` }}
    >
      <Banner></Banner>
      <Category></Category>

      <ServiceCard></ServiceCard>

       <WatchNow></WatchNow> 
      <LatestBlog></LatestBlog>
      <Subscribe></Subscribe>
    </div>
  );
};

export default Home;
