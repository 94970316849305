import React from 'react';
import useTitle from '../../../hooks/useTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import vector from '../../../assets/images/service/vectorservice.jpg';
const VectorServices = () => {
    useTitle("Vector Service");
    return (
        <div className='mt-20 px-20'>
          <div className='grid grid-cols-1 container mx-auto'>
          <h1 className='text-3xl text-blue-600 pt-10 font-bold pb-2'>What are Vector Services?</h1>

          <p>
          Vector services refer to creating vector graphics, which are digital images created using mathematical formulas. Unlike raster images, which are made up of pixels, vector graphics are made up of lines and curves, and they can be scaled to any size without losing quality. Vector services involve creating vector graphics for various purposes, including logos, illustrations, and diagrams.
          </p>

          
          <div className='grid grid-cols-2'>
              <div>
              <h2 className='pt-5 text-2xl text-blue-600'>Applications of Vector Services</h2>
          <p>Vector services have numerous applications in various industries, including:</p>
             
          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/>  1. Graphic Design : </h3>

          <p>
          Vector graphics are commonly used in graphic design for creating logos, icons, and illustrations. They can be easily resized, edited, and manipulated, making them a versatile tool for designers.
          </p>

          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/>  2. Advertising: </h3>

          <p>
          Vector graphics are used in advertising to create eye-catching graphics that can be used in various media, including billboards, brochures, and digital ads.
          </p>

          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 3. Engineering: </h3>
          <p>
          Vector graphics are used in engineering for creating technical drawings, diagrams, and schematics. They are precise and scalable, making them an essential tool for engineers.
          </p>

              </div>

              <div>
                  <img src={vector} className='rounded-xl' alt='Vector Service' />
              </div>
          </div>

          <h2 className='pt-5 text-2xl text-blue-600'>Benefits of Vector Services</h2>

          <p>  Here are some of the benefits of using vector services:</p>

          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 1. Scalability:  </h3>

          <p>
          Vector graphics can be scaled to any size without losing quality. This means that they can be used in various media, including print, digital, and large-format.
          </p>

          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 2. Precision:  </h3>
          <p>
          Vector graphics are created using mathematical formulas, making them precise and accurate. This is especially important in engineering and technical drawings, where precision is essential.
          </p>

          <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 3. Editability:   </h3>
           <p>Vector graphics can be easily edited and manipulated, allowing designers to make changes quickly and efficiently.</p>

           <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 4. Versatility:    </h3>

           <p>
           Vector graphics can be used for various purposes, including logos, illustrations, and diagrams. This makes them a versatile tool for designers, engineers, and advertisers.
           </p>

           <h2 className='pt-5 text-2xl text-blue-600'>Technologies Involved in Vector Services</h2>

           <p>
           Vector services involve using specialized software, including:
           </p>

           <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> Adobe Illustrator:   </h3>

           <p>Adobe Illustrator is a vector graphics editor that is widely used in graphic design, illustration, and technical drawing.</p>

           <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> CorelDRAW:   </h3>

           <p>
           CorelDRAW is a vector graphics editor that is widely used in graphic design, illustration, and technical drawing.
           </p>
           <h3 className='text-1xl text-dark font-bold pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> Inkscape:   </h3>

           <p>
           Inkscape is a free and open-source vector graphics editor that is widely used by designers and illustrators.
           </p>
           <h2 className='pt-5 text-2xl text-blue-600'>Conclusion</h2>
           <p className='pb-5'>
           Vector services are an essential aspect of graphic design, advertising, and engineering. They involve creating vector graphics using specialized software, and they offer numerous benefits, including scalability, precision, editability, and versatility. Whether it's creating logos, icons, illustrations, or technical drawings, vector services can help achieve professional-looking results that can make a significant difference in the success of any project.
           </p>





          </div>


        </div>
    );
};

export default VectorServices;