import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/multi/before/multiclipbefore.jpeg';
import after from '../../../../assets/images/beforeafter/multi/after/multiafter.jpg';
const MultiClippingPathba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default MultiClippingPathba;