import React from 'react';
import useTitle from '../../../hooks/useTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import ContactUs from '../../ContactUs/ContactUs';
import ClientSays from '../../AboutUs/ClientSays';
import back from '../../../assets/images/service/Background Removal.jpg';

const BackgroundRemoval = () => {
    useTitle("Background Removal");
    return (
        <div className='lg:mt-2 mt-20 mb-10'>
            <div className='grid grid-cols-1 container mx-auto px-5'>
                <h1 className='text-3xl text-blue-600 pt-32 font-bold pb-2'> Professional Photo Background Removal Service!</h1>
                <p>
                    Are you tired of struggling with complex photo editing software to remove unwanted backgrounds from your precious images?
                    <br></br> Our expert photo editing team is here to turn your vision into reality.
                </p>
                <div className='grid grid-cols-2'>
                    <div>
                        <h3 className='text-2xl text-dark font-medium pt-2'>What is Photo Background Removal?</h3>
                        <p>
                            Photo Background Removal is a basic image editing technique that helps us separate the main subject from the background.
                            <br></br> We can seamlessly replace the background or leave it with a transparent backdrop by carefully extracting the subject.
                        </p>
                        {/* Other sections... */}
                    </div>
                    <div>
                        <img src={back} className='rounded-xl' alt='Background Removal' />
                    </div>
                </div>
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Application of Photo Background Removal</h3>
                <h3 className='text-1xl text-dark font-bold pt-2'>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 1. E-commerce Product Photography
                </h3>
                {/* More application sections... */}
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Our Expertise in Photo Background Removal</h3>
                <h3 className='text-1xl text-dark font-bold pt-2'>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 1. Precision and Perfection
                </h3>
                {/* More expertise sections... */}
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Our Editing Services Include:</h3>
                <h3 className='text-2xl text-dark font-medium pt-2'>Background Removal</h3>
                {/* Other editing services... */}
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>How Does Our Process Work?</h3>
                <h3 className='text-1xl text-dark font-bold pt-2'>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 1. Image Submission:
                </h3>
                {/* More process steps... */}
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Experience the Difference Today</h3>
                <ClientSays />
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Trust Us for Professional Photo Editing</h3>
                {/* Other sections... */}
                <h3 className='text-2xl text-dark font-medium pt-2'>Contact Us Today!</h3>
                <ContactUs />
                {/* Other sections... */}
                <p>
                    [Samples of Our Work]
                </p>
                {/* Other sections... */}
            </div>
        </div>
    );
};

export default BackgroundRemoval;
