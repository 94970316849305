import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/shadow/shadowimgbefore.jpg';
import after from '../../../../assets/images/beforeafter/shadow/shadowimgafter.jpg';
const Shadowba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default Shadowba;