

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { faCircleQuestion, faDownload, faHourglassStart, faPersonBiking, faUpload } from '@fortawesome/free-solid-svg-icons';

const HowToWotk = () => {
    return (
        <div className='mt-40 container mx-auto' >
         <VerticalTimeline>
     
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    //contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
   // contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
   // date="2011 - present"
    iconStyle={{ background: 'rgb(0, 145, 255)', color: '#fff' }}
    
    icon={<FontAwesomeIcon icon={faHourglassStart} bounce/>}
  >
   
    <h3 className="vertical-timeline-element-title text-4xl font-bold text-blue-600">Step 01</h3>
    <h4 className="vertical-timeline-element-subtitle text-2xl pt-2">Can provide you with a quote. </h4>
    <p>
    Certainly, I'd be happy to help you with that! We offer image editing services with various options to choose from based on your needs. Here are the details
    Turnaround time: Our default turnaround time is 24 hours. However, we also offer faster turnaround times of 6 hours and 12 hours for an additional fee. If you're not in a rush, you can choose a longer turnaround time of 48 hours or 72 hours and receive a discount.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
   // date="2010 - 2011"
    iconStyle={{ background: 'rgb(0, 145, 255)', color: '#fff' }}
    icon={<FontAwesomeIcon icon={faUpload} flip/>}
  >
    <h3 className="vertical-timeline-element-title text-4xl font-bold text-blue-600">Step 02</h3>
    <h4 className="vertical-timeline-element-subtitle text-2xl pt-2">Upload your images .</h4>
    <p>
    Thank you for your request. We make it easy for you to upload your images in a secure and encrypted manner. Here are the details:

Web browser: You can upload your images directly through our website using your web browser. Simply navigate to the upload section, select your images, and upload them securely. We accept the following file types: JPG (preferred), PSD, TIFF, PNG, NEF, RAW, CR2, DNG, and PDF.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    //date="2008 - 2010"
    iconStyle={{ background: 'rgb(0, 145, 255)', color: '#fff' }}
    icon={<FontAwesomeIcon icon={faCircleQuestion} bounce/>}
  >
    <h3 className="vertical-timeline-element-title text-4xl font-bold text-blue-600">Step 03</h3>
    <h4 className="vertical-timeline-element-subtitle text-2xl pt-2">Great! Once you receive your customized quote for our image editing services</h4>
    <p>
    After receiving your customized quote for our image editing services, you have the flexibility to make any final adjustments as per your requirements
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    //date="2006 - 2008"
    iconStyle={{ background: 'rgb(0, 145, 255)', color: '#fff' }}
    icon={<FontAwesomeIcon icon={faPersonBiking} shake/>}
    
  >
    <h3 className="vertical-timeline-element-title text-4xl font-bold text-blue-600">Step 04</h3>
    <h4 className="vertical-timeline-element-subtitle text-2xl pt-2">Great! Now that you've approved your quote and we're working on editing your images, you can sit back and relax,</h4>
    <p>
    Whether it's spending time with loved ones, focusing on your work, or enjoying your hobbies, you can rest assured that your images are in good hands.

We'll keep you updated on the progress of your project, and if we need any further information or clarification, we'll reach out to you promptly. Our team of expert editors will ensure that your images are edited to your satisfaction and delivered to you within the specified turnaround time.

In the meantime, feel free to reach out to us if you have any questions or concerns. We're here to provide you with the best possible image editing services and are committed to ensuring your satisfaction.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    //date="April 2013"
    iconStyle={{ background: 'rgb(0, 145, 255)', color: '#fff' }}
    icon={<FontAwesomeIcon icon={faDownload} flip/>}
  >
    <h3 className="vertical-timeline-element-title text-4xl font-bold text-blue-600">Step 05</h3>
    <h4 className="vertical-timeline-element-subtitle text-2xl pt-2">Download your professionally edited images.</h4>
    <p>
    Once your photos are ready, we’ll email you a link to download the files, all ready to go.
    </p>
  </VerticalTimelineElement>
 
</VerticalTimeline>


   
        </div>
    );
};

export default HowToWotk;