import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/neck/neckjointbefor.jpg';
import after from '../../../../assets/images/beforeafter/neck/neckjointafter.jpg';
const NeckJointba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
  
};

export default NeckJointba;