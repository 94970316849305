import React from 'react';
import ReactCompareImage from 'react-compare-image';
import before from '../../../../assets/images/beforeafter/color curation/before/colorbefore.jpg';
import after from '../../../../assets/images/beforeafter/color curation/before/colorafter.jpg';
const ColorChangesba = () => {
    return (
        <ReactCompareImage hover="true" 
        leftImageLabel='Before'
        rightImageLabel='After'
        leftImage={before} rightImage={after}
        />
    );
};

export default ColorChangesba;