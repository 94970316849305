import React from 'react';
import HomeCat from '../HomeCat/HomeCat';

const Category = () => {
    return (
        <div className=' container mx-auto mt-10'>
             <h2 className='text-4xl font-bold text-blue-600 pb-10'>What We Do</h2>
      <HomeCat></HomeCat>



        </div>
    );
};

export default Category;