import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowPointer } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import pricingim from '../../assets/images/price.jpg';

const Pricing = () => {
    return (
        <div className='mt-20 container mx-auto'>
               <div className='text pt-10'>
               <h2 className='text-4xl text-blue-600 font-bold text-center uppercase'>Pricing Chart</h2>
               <h2 className='text-2xl text-center pb-5'> It seems like you're describing  pricing structure for photo editing services, particularly focusing on clipping path services. </h2>
                <div className='grid lg:grid-cols-2 grid-cols-1'>
                     {/* one */}
                     <div className='mt-10 lg:px-0 px-5'>
                    
               <h2 className='text-2xl  text-blue-600'> Here's a breakdown of your pricing strategy:</h2>
               <h3 className='text-1xl   pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 1. Lowest Price: You claim to offer competitive pricing in the market.</h3>
               <h3 className='text-1xl   pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 2. Price Determinants: The price depends on factors such as the time taken for the work and the complexity of the image.</h3>
               <h3 className='text-1xl   pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 3. Volume Discount: Customers ordering a large number of images can benefit from reduced prices.</h3>
               <h3 className='text-1xl   pt-2'><FontAwesomeIcon icon={faArrowAltCircleRight} className='pr-2 text-blue-600' fa-beat/> 4. Custom Pricing: You're open to negotiating prices based on individual projects, indicating flexibility in your pricing approach.</h3>
               
               <Link to='/contactus' className='btn mt-5 bg-white border-blue-600 border-2 text-blue-600 hover:bg-blue-600 hover:border-blue-600 hover:text-white'> <FontAwesomeIcon icon={faArrowPointer} className='pr-2' fa-beat/> Get a Quote</Link>
                     </div>
                     {/* two */}
                     <div>
                     <figure className="px-10 pt-10">
    <img src={pricingim} alt="Shoes" className="rounded-xl" />
  </figure>
                     </div>
                </div>

   
               </div>

               <div className="card-body">
                                    <table class="table table-hover table-striped">
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>CLIPPING PATH SERVICE</th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Basic Clipping Path</td>
                                                <td>$0.49</td>
                                            </tr>
                                            <tr>
                                                <td>Simple Clipping Path</td>
                                                <td>$0.80</td>
                                            </tr>
                                            <tr>
                                                <td>Compound Clipping Path</td>
                                                <td>$2.50</td>
                                            </tr>
                                            <tr>
                                                <td>Extra Clipping Path</td>
                                                <td>$3.50</td>
                                            </tr>
                                            <tr>
                                                <td>Complex Clipping Path</td>
                                                <td>$2.49</td>
                                            </tr>
                                            <tr>
                                                <td>Multi Clipping Path</td>
                                                <td>$5.00</td>
                                            </tr>
                                            <tr>
                                                <td>Super Complex Clipping Path</td>
                                                <td>$8.00</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>Clipping Path with Shadow </th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Original Shadow</td>
                                                <td>$2.00</td>
                                            </tr>
                                            <tr>
                                                <td>Drop Shadow</td>
                                                <td>$1.50</td>
                                            </tr>
                                            <tr>
                                                <td>Reflection Shadow</td>
                                                <td>$1.80</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>Masking</th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Hair Masking</td>
                                                <td>$3.50</td>
                                            </tr>
                                            <tr>
                                                <td>Transparent Masking</td>
                                                <td>$2.00</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>Color Correction</th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Color Correction</td>
                                                <td>$2.99</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>Raster to Vector </th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Raster to Vector</td>
                                                <td>$10.00</td>
                                            </tr>
                                        </tbody>
                                        <thead>
                                            <tr class="bg-primary text-white">
                                                <th className='bg-blue-600'>Graphics Design </th>
                                                <th className='bg-blue-600'>STARTING PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Graphics Design</td>
                                                <td>$50</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
        </div>
    );
};

export default Pricing;